@import '../../assets/scss/variable.scss';

.suggestions {
  &-list-item {
    height: 44px;
    padding: 0 15px;
    width: 100%;
  }

  &-left {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
    position: relative;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-right,
  &-left {
    margin: 0 -5px;

    .la-icon {
      padding: 0 5px;
    }

    span {
      font-size: 0.8125rem;
    }
  }

  &-selected {
    background: $soft-gray;
    height: 50px;
    padding: 0 15px;
    flex-direction: row;
  }
}

.autocomplete-wrapper {
  .form-control-select-label-outside & {
    .form-control-label {
      font-size: $fz-14;
      padding-bottom: 7.5px;
      display: block;
      line-height: 1;
    }
  }

  .autocomplete-form-control-wrapper {
    .form-control-label {
      font-size: $fz-14;
    }
  }
}
.autocomplete {
  &-wrapper {
    position: relative;
  }

  &-form-control-wrapper {
    position: relative;
    z-index: 2;
  }

  &-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    border: solid 1px rgba(60, 56, 53, 0.2);
    border-bottom-width: 0;
    margin-top: -1px;
    z-index: 1;
    background-color: #fff;
    @include box-shadow(2px, 2px, 4px, 0, rgba(15, 34, 65, 0.1));
    @include border-radius(0px 0px 3px 3px);

    .windowed & {
      border: 0;
      box-shadow: none;
      margin-top: 0;

      .suggestions-list-item {
        height: 55px;
      }
    }
  }
}
