@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.link {
  font-size: $fz-12;
  font-weight: $medium;
  text-decoration: none;
  color: rgba($default, 0.4);
  @include transition(color, 0.1s, ease);
  user-select: none;
  display: flex;
  padding: 12px 17px;

  .autocomplete-item-selected & {
    color: rgba($default, 0.5) !important;
    pointer-events: none !important;
  }

  .list-nav-side & {
    display: block;
    color: rgba($primary, 0.5);
    padding: 13.5px 17px;
    margin: -13.5px -17px;
  }

  &:hover {
    .list-nav-side & {
      color: rgba($primary, 0.5);
      background-color: rgba($primary, 0.1);
    }
  }

  &.link-active {
    &:hover {
      cursor: default;
    }

    .list-nav-side & {
      color: #fff;
      background-color: $primary;
    }
    color: $default;
  }

  &:hover {
    color: $default;
  }

  .link-button & {
    color: $default;
    pointer-events: none;
  }

  &-clickable {
    margin: -30px 0px;
    display: inline-block;
    padding: 12px 17px;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    .link-button & {
      pointer-events: all;
    }
  }

  &-button {
    font-size: $fz-13;
    max-height: 50px;
    padding: 16.5px 16px;
    font-weight: $regular;
    background-color: rgba($default, 0.05);
    @include border-radius(3px);

    &:hover {
      .list-clear & {
        background-color: rgba($default, 0.05);
      }
    }

    .list-clear & {
      background-color: $none;
    }
  }

  &-has {
    &-sufix {
      padding-left: 40px;
    }

    &-prefix {
      padding-right: 40px;
    }
  }

  &-wrapper {
    position: relative;
  }

  &-sufix,
  &-prefix {
    .link-wrapper & {
      position: absolute;
      top: 50%;
      @include translate(0, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }
  }

  &-sufix {
    .link-wrapper & {
      right: 0;
    }
  }

  &-prefix {
    .link-wrapper & {
      left: 0;
    }
  }

  &-active-bg-blue {
    background-color: $headers;
    color: $white;
  }

  .main-aside & {
    width: 100%;
    padding: 18px 40px;
    display: inline-block;
  }
}
