.message-popup {
  z-index: 20;
  // z-index: 9999999999;
  min-width: 600px;
  background: #fff;
  border-radius: 25px;
  padding: 30px 20px 20px;
  position: relative;

  h2 {
    margin-bottom: 20px;
  }

  label {
    padding: 0px 0px 0px 16px;

    &.btn {
      padding: 0;
      width: 40%;
    }
  }

  .form-control-wrapper {
    margin-bottom: 15px;

    .form-control-container {
      height: 60px;
    }
  }

  .grey-input.form-control,
  .grey-input {
    color: #050e3b;
    background-color: #f7f7f9;
    height: 60px;
    padding: 15px 15px 0;
    width: 100%;
    box-sizing: border-box;
  }

  .text-area {
    min-height: 150px;

    .form-control {
      min-height: 150px;
    }
  }

  .message-complete-btn {
    width: 40%;
  }

  .textarea {
    min-height: 140px;
    border: none;
    resize: none;
    padding-top: 40px;
    border: 1px solid;
    border-color: #eceeef;
  }

  .textarea-label.not-empty {
    transform: translate(0, -100%);
  }

  .textarea:focus + .textarea-label {
    font-size: 0.625rem;
    color: rgba(5, 14, 59, 0.5);
    transform: translate(0, -100%);
  }

  .textarea-label {
    position: absolute;
    left: 0;
    top: 30px;
    transform: translate(0, -50%);
    font-size: 0.8125rem;
    padding: 0px 0px 0px 16px;
    color: rgba(5, 14, 59, 0.5);
    transition: transform 0.2s ease, font-size 0.2s ease;
    &.text-area-filled {
      font-size: 0.625rem;
      color: rgba(5, 14, 59, 0.5);
      transform: translate(0, -100%);
      background-color: #f7f7f9;
      padding-top: 14px;
      width: 95%;
      border-left: 1px solid #eceeef;
    }
  }

  .textarea-holder {
    position: relative;
  }

  .success-message {
    color: #4ebd31;
    display: flex;
    padding: 20px;
    justify-content: center;

    .icon-svg-tick {
      margin-right: 10px;
    }

    .tmp-success-text {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      span:first-child {
        margin-bottom: 10px;
      }
    }
  }

  .attachment-list-wrap {
    max-height: 20%;
    overflow: auto;
    margin-top: -4px;

    .attachment-list {
      color: #050e3b;
      background-color: #f7f7f9;
      margin-top: -5px;
      padding: 0 15px;
      font-size: 0.8125rem;
      color: rgba(5, 14, 59, 0.5);
      border: 1px solid;
      border-color: #eceeef;

      img {
        width: 15px;
        margin-right: 5px;
      }
    }
  }

  .delete-doc {
    cursor: pointer;
  }

  .tmp-popup-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    .tmp-popup-column {
      flex-grow: 1;
      width: 45%;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
