@media screen and (max-width: $screen-xs-max) {
  .table-wrapper table {
    width: 250vw;
  }
  .documents-list-holder,
  .correspondence-list-holder {
    display: block;
  }
  .table-list-holder {
    overflow-x: scroll;
  }
  .breadcrumb-list-item-wrapper .link {
    font-size: 0.75rem;
    line-height: 0.5rem;
  }
  .breadcrumb-list-item-wrapper:after {
    content: '/';
    position: relative;
    top: -6px;
    font-size: 0.75rem;
    color: rgba(5, 14, 59, 0.4);
    padding: 0 5px;
  }
  .hide-on-mobile {
    display: none;
  }
  .full-width {
    width: 100%;
  }

  .list-wrapper {
    overflow: scroll;
    overflow-y: hidden;
  }
  .col-sd-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .form-inline-divide .form-group {
    max-width: 100%;
  }

  .breadcrumb-list {
    padding-top: 15px;

    .breadcrumb-list-item-wrapper {
      margin-top: 0;
    }
  }

  .wrapper-with-button {
    .main-content {
      height: calc(100% - 50px);

      & > .container-fluid {
        height: 100%;

        & > .row {
          height: 100%;
        }
      }
    }
  }

  .main-content {
    height: 100%;

    .details-with-aside .details-content {
      padding: 20px 20px 0;

      .form-disabled {
        padding-bottom: 0;
      }
    }
  }

  .breadcrumb-wrapper {
    display: none;
  }

  .customer-built-in-header {
    height: 48px;
    padding: 0;

    .list-nav-side {
      .link {
        margin: -13px -10px;
      }

      &.list-inline {
        margin: 0;
        padding: 0 10px;
        justify-content: space-between;
      }
    }
  }

  .built-in-header ~ .main-wrapper {
    height: calc(100vh - 100px);
  }

  .account-header {
    z-index: 5;
    display: flex;
    flex-direction: row;
  }

  .message-mobile-holder {
    margin-right: 15px;
  }

  .combined-inputs {
    .form-group {
      margin: 0;

      &:first-child {
        margin-left: 0px;
        margin-bottom: 0;
      }
      &:last-child {
        margin-right: 0px;
      }
    }

    .form-inline-divide {
      border: 1px solid #cecece;

      .form-control-sufix,
      .form-control-prefix {
        width: 32px;
      }
    }
  }

  .pdf-viewer,
  .mobile-preview-holder,
  .mob-preview {
    height: 94%;
    align-items: center;
  }

  #root {
    height: 100%;
  }

  .mob-preview iframe {
    height: 100% !important;
  }

  .brand {
    margin-left: -15px;
  }

  .contact-btn-group {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 40px;
    position: absolute;
    bottom: 0;

    .contact-btn {
      margin: 0;
      width: 50%;
      border-radius: 0;

      &:first-child {
        margin-right: 2px;
      }

      &:last-child {
        margin-left: 2px;
      }
    }
  }

  .form-inline-divide .main-header {
    padding: 0 20px;
  }

  .details-with-aside {
    .task-details-wrapper {
      padding-top: 0;
      padding-right: 40px;
      width: 100%;

      .details-content {
        padding: 0;
      }
    }
  }

  .body-message-wrapper .message-header .line {
    display: flex;
  }
  .customer-built-in-header {
    .list-item-wrapper {
      a {
        text-align: center;
      }
    }
  }

  .form-control-wrapper-label-inside {
    .form-control-label-animate {
      .form-control {
        border-width: 0;
      }
    }
  }

  .message-popup {
    min-width: unset;
    width: 90%;
  }

  .main-header {
    padding: 0 12px;
  }
}

.account-header {
  z-index: 5;
  display: flex;
  flex-direction: row;
}
.message-mobile-holder {
  margin-right: 15px;
}
