@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.customer {
  &-built-in-header {
    padding: 0 30px;
    position: relative;
    align-items: center;
    @include box-shadow(0, 2px, 10px, 1px, $box-shadow-color);
    .h2 {
      font-size: $fz-20;
      margin: 20px 0 4px;
      line-height: 1;
    }

    .list-nav-side {
      &.list-inline {
        margin: 0 -10px;
      }

      .list-item-wrapper {
        padding: 15px 10px;
      }

      .link {
        border-bottom-width: 3px;
        border-bottom-color: $white;
        border-bottom-style: solid;
        color: rgba($color: $default, $alpha: 0.8);
        transition: $transition;
        padding: 15px 10px;
        margin: -15px -10px;
        font-size: $fz-13;
        font-weight: normal;

        &:hover {
          color: $default;
          background-color: transparent;
          border-bottom-color: $white;
        }
        &.link-active {
          color: $default;
          background-color: transparent;
          border-bottom-color: $success;
        }
      }
    }
    @media screen and (max-width: $screen-xs-max) {
      padding: 0 20px;
    }
  }
}

.full-height {
  height: 100%;
}

.status-btn {
  align-self: center;
  margin-right: 20px;
}

.claim-status-holder {
  min-width: 120px;
  font-size: 0.75rem;
  height: 40px;
  color: #050e3b;
  opacity: .7;
  font-weight: 500;
  border-radius: 3px;
  border-width: 0;
  border-style: solid;
  border-color: #eceeef;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f9;
  text-align: center;
  margin-right: 10px;
  padding: 0 10px;
}
