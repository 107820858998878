@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.list {
  &-wrapper {
    .autocomplete-wrapper > & {
      position: relative;
      z-index: 1;
    }
  }

  &-inline {
    display: flex;
    margin: 0 -17px;
  }

  &-item {
    &-wrapper {
      line-height: 1;
      align-items: center;
      display: flex;

      & + .list-item-wrapper {
        .list-clear & {
          border-top: solid 1px #cfd3d9;
        }
      }

      .list-nav-side & {
        padding: 13.5px 17px;
      }

      .main-aside & {
        padding: 0;
      }

      .list-clear & {
        padding: 0;
      }

      .list-row & {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.with-scroll {
    &-y {
      overflow-y: auto;
      max-height: 220px;
    }
  }
}
