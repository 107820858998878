@import '../../assets/scss/variable.scss';

.error-container {
  padding: 15%;

  p {
    font-size: $fz-12;
  }

  .btn {
    margin-top: 20px;
  }

  .error-content {
    border: 1px solid $soft-gray;
    -webkit-box-shadow: 0 0 15px 0 rgba(5, 14, 59, 0.1);
    -moz-box-shadow: 0 0 15px 0 rgba(5, 14, 59, 0.1);
    box-shadow: 0 0 15px 0 rgba(5, 14, 59, 0.1);
    padding: 25px;
  }
}
