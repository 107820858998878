@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';
.select__styles {
  font-size: $fz-13;
  max-height: 50px;
  padding: 16.5px 16px;
  border-width: 1px;
  border-style: solid;
  @include border-radius(3px);
  background-color: $white;
  width: 100%;

  // @media only screen and (min-width: 1200px) {
  //   font-size: $fz-13;
  // }
}
.form-control {
  &-select {
    @extend .select__styles;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:hover {
      cursor: pointer;
    }

    .form-control-select-label-inside & {
      padding: 22px 30px 0 15px;
      height: 50px;
      &:disabled {
        padding-right: 15px;

        & + label {
          padding-right: 15px;
        }
      }
    }
    &-group {
      position: relative;
    }
    &-label {
      font-size: $fz-14;
      .form-control-select-label-outside & {
        line-height: 1;
        display: block;
        padding-bottom: 7.5px;
        color: $default;
      }
      .form-control-select-label-inside & {
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 40%;
        font-size: $fz-13;
        color: rgba($default, 0.5);
        padding-left: 15px;
        padding-right: 30px;
        @include transform(translate(0, -50%));
        transition: transform 0.2s ease, font-size 0.2s ease;
      }
      .form-control-select-label-inside.control-active & {
        @include filled-input-label;
        .pointer-without-events {
          @include pointer-without-events;
        }
      }
      &-outside {
        display: flex;
        flex-direction: column;
      }
    }
    .popup-global & {
      background-color: $soft-gray;
      border: 0;
    }
  }
  &.has-error {
    border-color: $danger !important;
    .form-control-label-static & {
      border-color: $danger !important;
      @include box-shadow(0, 0, 0, 2px, rgba($danger, 0.3));
    }
  }
}
.claims-details {
  .form-control-select-group {
    display: flex;
    flex-direction: column;
  }

  .form-control-select-group .la-icon-angle {
    display: block;
    top: auto;
    bottom: 18px;
  }
  .form-control-select-label-inside {
    .form-control-select-label {
      padding-top: 15px;
      order: -4;
    }
  }
}

.contact-us-modal-select {
  color: $default;
  margin-bottom: 15px;
  background-color: #f7f7f9;
}

.tmp-message-email-name {
  color: $default;
  margin-bottom: 10px;
  background-color: #f7f7f9;
}

.select-style {
  font-size: $fz-13;
}
