@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.form-errors {
  color: $danger;
  font-size: $fz-11;

  .form-control-wrapper & {
    margin-top: 5px;
  }
}

.form {
  &-errors {
    p:first-letter {
      text-transform: capitalize;
    }
  }
}
