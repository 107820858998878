@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';

.file__styles {
  font-size: $fz-13;
  width: 100px;
  height: 100px;
  // background-color: $main-background;
  position: relative;
  // margin: 5px 0;
  margin: 5px 0 5px 15px;

  &:hover {
    cursor: pointer;
  }
}

.form-control-file {
  &-container {
    width: 100%;
    background-color: $main-background;
    padding-bottom: 5px;

    .new-design-file-upload-label {
      border-radius: 15px 15px 0 0;
    }
  }

  &-wrapper {
    @extend .file__styles;

    .btn-background {
      position: absolute;
      width: 100%;
      height: 100%;

      .background-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
      }

      .background-remove {
        z-index: 1;
        position: absolute;
        top: 5px;
        right: 5px;
        background: $white;
        border-radius: 50%;
        opacity: 0.5;
        transition: all 0.1s linear;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &-label {
    font-size: $fz-14;
    @media only screen and (min-width: 1200px) {
      font-size: $fz-13;
    }
    width: 100%;
    display: block;
    background-color: #f7f7f9;
    padding: 7px 15px;
    color: rgba(5, 14, 59, 0.5);
    border-radius: 3px;
  }

  &-sublabel {
    font-size: $fz-12;
    opacity: 0.5;
    word-break: break-all;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.disabled {
    background-color: $muted;
  }

  &-group {
    position: relative;
  }
}
.modal-content {
  padding: 10px;
  background: #fff;
  max-height: 90vh;
  max-width: 80vw;
  border-radius: 5px;
  display: inline-block;
  position: relative;

  &-switch-left {
    position: absolute;
    top: 50%;
    z-index: 10001;
  }

  &-switch-right {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 10001;
  }
  &-switch-left-block {
    position: absolute;
    top: 50%;
    z-index: 10001;
    width: 40px;
    height: 40px;
  }

  &-switch-right-block {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 10001;
    width: 40px;
    height: 40px;
  }

  .modal-img {
    height: -webkit-fill-available;
    border-radius: 5px;
    max-height: 80vh;
    max-width: 70vw;
  }
  .modalClose-btn {
    position: absolute;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    top: -50px;
    right: -50px;
    &:hover {
      cursor: pointer;
      color: rgb(212, 207, 207);
    }
  }
}
