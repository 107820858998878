.file-loader {
  &-multiple {
    padding: 5px 0;
    margin-left: 15px;

    .multiple-file {
      margin: 0 5px;
    }
  }
}

.file-loader {
  &-multiple {
    // margin: 0 -5px;
    margin: 0 -5px 0 2px;

    .upload-form {
      max-width: 100px;
    }

    label.cursor-without-hand:hover {
      cursor: auto;
    }
  }
}

.files-load-wrapper {
  margin: 0 -5px;

  .form-control-file-sublabel {
    margin: 0 5px;
  }
}

$main-background: #f7f7f9;
.form-control-multiplefile-container {
  width: 100%;
  background-color: $main-background;
  padding-bottom: 5px;

  .new-design-multiple-file-upload-label {
    border-radius: 15px 15px 0 0;
  }
}
