.ring-loader {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  pointer-events: auto;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.3);
}
