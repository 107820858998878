@font-face {
  font-family: 'NeueHaasGrotesk';
  src: url('../fonts/NeueHaasGrotesk/NeueHaasDisplay-Thin.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'NeueHaasGrotesk';
  src: url('../fonts/NeueHaasGrotesk/NeueHaasDisplay-Roman.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'NeueHaasGrotesk';
  src: url('../fonts/NeueHaasGrotesk/NeueHaasDisplay-Medium.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
