@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.button-group {
  display: flex;
  border-width: 1px;
  border-style: solid;
  border-color: #eceeef;

  &-wrapper {
    display: inline-block;
  }

  &-item {
    &-wrapper {
      & + .button-group-item-wrapper {
        margin-left: -1px;

        .btn {
          border-left-width: 1px;
          @include border-radius(0px);
        }
      }

      &:last-child {
        .btn {
          @include border-radius(0px 3px 3px 0px);
        }
      }
    }
  }
}
