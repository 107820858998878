@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.alert {
  padding: 30px 20px;
  font-size: $fz-13;
  line-height: 1.54;
  margin: 10px 0;
  @include border-radius(3px);

  &-title {
    font-size: $fz-16;
    font-weight: $medium;
  }

  &-description {
    opacity: 0.8;
  }
}
