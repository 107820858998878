.click-to-address {
  .form-control-label {
    &.max-index {
      z-index: 99999999;
    }
  }
}

.new-design-click-to-address-label {
  border-radius: 15px !important;
}
