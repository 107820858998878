.h0,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 5px 0 10px;
  font-weight: $bold;
  backface-visibility: hidden;
}

.h1,
h1 {
  font-size: $h1;
}

.h2,
h2 {
  font-size: $h2;

  &-404 {
    margin-top: 0;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 3px;
  }
}
h3 {
  font-size: $h3;
}

.h4,
h4 {
  font-size: $h4;

  .link & {
    margin: 0;
  }
}

.h0 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small {
  font-size: 50% !important;
}

.h0 .label .h1 .label,
.h2 .label,
.h3 .label,
.h4 .label,
.h5 .label,
.h6 .label,
h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
  font-size: 75% !important;
}

.text {
  color: $primary;

  &-capitalize {
    text-transform: capitalize;
  }
  &-underline {
    text-decoration: underline;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-normal {
    text-transform: none;
  }
  &-hide {
    text-indent: -99999px;
    color: $none;
  }
  &-right {
    text-align: right;
  }
  &-line-through {
    text-decoration: line-through;
  }
  &-center {
    text-align: center;
    &-xs {
      @media (max-width: $screen-xs-max) {
        text-align: center;
      }
      @media (min-width: $screen-sm-min) {
        text-align: left;
      }
    }
  }
  &-left {
    text-align: left;
    &-xs {
      @media (max-width: $screen-xs-max) {
        text-align: left;
      }
    }
  }

  &-last {
    &-right {
      text-align-last: right;
      direction: rtl;
    }
    &-left {
      text-align-last: left;
    }
  }
}

.word-break {
  word-break: break-word;
}

.hidden-text {
  line-height: 0;
}

.no-wrap {
  white-space: nowrap;
}

.align {
  &-unset {
    vertical-align: unset !important;
  }
}

.first {
  &-letter {
    &-up {
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.sub-label {
  font-size: 70%;
  color: rgba($default, 0.5);
  margin-left: 10px;
}

.title {
  &-group {
    padding-bottom: 45px;

    @media (max-width: $screen-sm-max) {
      padding-bottom: 15px;
    }
  }
}

.letter {
  &-space {
    &-xs {
      letter-spacing: 0.5px;
    }

    &-sm {
      letter-spacing: 1px;
    }

    &-md {
      letter-spacing: 2px;
    }

    &-lg {
      letter-spacing: 2.5px;
    }
  }
}
