@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.main-aside {
  .list-wrapper {
    border-bottom: 1px solid $border-color;
  }
  .list-item-wrapper {
    width: 100%;
    text-align: center;
  }
  .link {
    padding: 18px 5px 15px;
    border-bottom-color: $white;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    transition: $transition;
    box-sizing: border-box;
    svg {
      fill: rgba($color: $default, $alpha: 0.5);
    }
    &:hover {
      svg {
        fill: $default;
      }
    }
    &.active {
      border-bottom-color: $success;
      svg {
        fill: $default;
      }
    }
  }
  .list {
    justify-content: space-between;
  }
}
