.react-datepicker {
  &__time-container {
    width: 100px;
  }

  &__time {
    &-box {
      width: 100px !important;
    }
  }
}
