html,
body {
  min-width: 320px;
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;
  background-size: cover;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  color: black;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
}

ul li {
  list-style-type: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

.none {
  display: none !important;
}

template {
  display: none;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 0.1rem dotted;
}

b,
strong {
  font-weight: 700;
}

em,
address,
cite,
dfn,
i,
var {
  font-style: italic;
}

h1 {
  font-size: 2rem;
  margin: 0;
}

mark {
  background: #e8cb2e;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  height: auto;
  vertical-align: middle;
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

blockquote,
figure {
  margin-right: 0;
  margin-left: 0;
}

audio,
canvas,
video {
  display: inline-block;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
  line-height: normal;
}

small,
sub,
sup {
  font-weight: 300;
}

p:first-child,
ul:first-child,
ol:first-child,
dl:first-child,
blockquote:first-child,
pre:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

p,
ul,
ol,
dl,
blockquote,
pre,
td,
th,
label,
textarea,
caption,
details,
figure {
  line-height: 1.5;
  margin-bottom: 0;
}

li p,
li .p-like,
li ul,
li ol {
  margin-top: 0;
  margin-bottom: 0;
}

img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
video {
  max-width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

button {
  overflow: visible;
  outline: none;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  &:focus {
    outline: none;
  }
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 0.1rem solid #c0c0c0;
  margin: 0 0.2rem;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.disabled {
  background-color: #edeef0 !important;
}

.disabled:hover {
  background-color: #edeef0 !important;
}
input:disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 1);
  -webkit-opacity: 1;
}

td.unset-capitalize div {
  text-transform: unset !important;
}
