.set-password-hero {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/set_password_backgr.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 0;
  bottom: 0;
  @media screen and (max-width: 1700px) {
    width: 110%;
    height: 110%;
  }
}

.set-password-login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 40%;
  left: 20%;
  z-index: 90;
  width: 380px;

  @media screen and (max-width: 600px) {
    position: initial;
  }

  .tmp-welcome-text {
    color: #fff;
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
  }

  .tmp-login-legal-text {
    color: #fff;
    font-family: Roboto;
    margin-top: 70px;
    text-align: center;
  }

  .to-login-link {
    color: #191b36;
    margin-top: 20px;
    padding: 0;
    opacity: 0.7;
    font-family: PT serif;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      opacity: 1;
    }
  }

  .btn-set-password {
    color: #fff;
    background-color: #191b36;
    min-height: 45px;
    border-radius: 3px;
    margin: 0 17px 0 0;
    font-family: NeueHaasGrotesk;
    font-weight: 550;
    font-size: 14px;
    letter-spacing: 1px;
  }
}

.xceedance-set-password-login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30%;
  left: 20%;
  z-index: 90;
  width: 380px;

  @media screen and (max-width: 600px) {
    position: initial;
  }

  .tmp-welcome-text {
    color: #fff;
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
  }

  .tmp-login-legal-text {
    color: #fff;
    font-family: Roboto;
    margin-top: 70px;
    text-align: center;
  }

  .to-login-link {
    color: #191b36;
    margin-top: 20px;
    padding: 0;
    opacity: 0.7;
    font-family: PT serif;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      opacity: 1;
    }
  }

  .btn-set-password {
    color: #fff;
    background-color: #191b36;
    min-height: 45px;
    border-radius: 3px;
    margin: 0 17px 0 0;
    font-family: NeueHaasGrotesk;
    font-weight: 550;
    font-size: 14px;
    letter-spacing: 1px;
  }
}
