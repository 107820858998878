html {
  --main-color: #e73056;
}

$default: #050e3b;
$white: #fff;
$headers: #2e3e53;
$success: var(--main-color);
$success-secondary: #f0fff0;
$success-default: #55d63b;
$primary: #050e3b;
$primary-redesign: linear-gradient(90deg, #e59ea7 0%, #dfb0b3 100%);
$primary-secondary: #c3d0ff;
$primary-secondary: rgba(#fff, 0.5);
$gray: gray;
$gray-light: #f5f6f8;
$soft-gray: #f7f7f9;
$muted: #e0e0df;
$muted-secondary: #fffff7;
$danger: #e73056;
$soft-pink: #ff6b8c;
$danger-secondary: #fcf2f3;
$warning: #ff9c32;
$warning-secondary: #fff2e3;
$info: #00c6ff;
$info-secondary: #f8f8f8;
$normal: #f7f7f9;
$normal-secondary: #f8f8f8;
$none: transparent;
$current: currentColor;

$main-background: #f7f7f9;
$color-secondary: #bbbdca;
$border-color: #e8e8eb;
$border-radius: 0.25rem;
$transition: 0.3s ease;
$box-shadow-color: rgba(
  $color: $default,
  $alpha: 0.1,
);

$error-primary: red;

/*
html -> font-size: 16px || font-size: 100% (16px)
10px = 0.625rem
12px = 0.75rem
13px = 0.8125rem
14px = 0.875rem
16px = 1rem (base)
17px = 1.0625rem
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem

http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
*/

$fz-10: 0.625rem;
$fz-11: 0.7rem;
$fz-12: 0.75rem;
$fz-13: 0.8125rem;
$fz-14: 0.875rem;
$fz-16: 1rem;
$fz-16x: 1.025rem;
$fz-17: 1.0625rem;
$fz-18: 1.125rem;
$fz-20: 1.25rem;
$h1: 2rem;
$h2: 1.5rem;
$h3: 1.25rem;
$h4: $fz-18;
$roboto: 'Roboto', 'Arial', sans-serif;
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

$text-up: uppercase;
$text-cap: capitalize;
$text-low: lowercase;
$text-nor: normal;

// Media

$screen-xs-max: 576px;
$screen-sm-min: 577px;
$screen-sm-max: 767px;
$screen-md-min: 768px;
$screen-md-max: 991px;
$screen-lg-min: 992px;
$screen-lg-max: 1199px;
$screen-xl-min: 1200px;
$screen-xl-max: 1599px;
$screen-xxl-max: 1600px;

$input-font: $fz-16;
