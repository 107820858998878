@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';

// remove arraws from the number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// remove arraws from the number input
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.is_empty {
  display: none;
  padding: 0;
  margin: 0;
  height: 0;
  visibility: hidden;
  pointer-events: none;
}

.input__styles {
  font-size: $fz-13 !important;
  max-height: 50px;
  padding: 13px 16px;
  border-width: 1px;
  border-style: solid;
  @include border-radius(3px);
  width: 100%;
}

.form-control {
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #eceeef;
  @include box-shadow(0, 0, 0, 2px, rgba($default, 0));
  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  &-wrapper {
    position: relative;

    .popup & {
      width: 100%;
    }

    &-innerhtml {
      background-color: $normal;
    }
  }

  &:focus {
    @include box-shadow(0, 0, 0, 2px, rgba($default, 0.02));
  }

  &-focus {
    @include box-shadow(2px, 2px, 4px, 0, rgba(15, 34, 65, 0.1) !important);
    @include border-radius(3px 3px 0 0 !important);
  }

  &-sufix,
  &-prefix {
    position: absolute;
    top: 50%;
    @include translate(0, -50%);
    width: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &-sufix {
    right: 0;
    cursor: pointer;
  }

  &-prefix {
    left: 0;
  }

  &-has {
    &-sufix {
      padding-right: 40px !important;
    }

    &-prefix {
      padding-left: 40px !important;
    }
  }

  &.has-error {
    border-color: $danger;
    .form-control-label-static & {
      border-color: $danger;
      @include box-shadow(0, 0, 0, 2px, rgba($danger, 0.3));
    }
  }

  .form-control-label-static & {
    @extend .input__styles;
  }

  &-label {
    transition: transform 0.2s ease, font-size 0.2s ease;

    .form-control-wrapper-label-static &,
    .form-control-label-static & {
      display: block;
      padding-bottom: 7.5px;
      font-size: $fz-14;
    }
  }

  &-label {
    &:empty {
      @extend .is_empty;

      & > * {
        &:empty {
          @extend .is_empty;
        }
      }
    }
    .form-control-wrapper-label-inside & {
      pointer-events: none;
      padding: 0px 16px 0px 16px;
    }

    .form-control-wrapper-label-static & {
      line-height: 1;
    }

    &-static {
      position: relative;
      display: flex;
      flex-direction: column-reverse;

      span {
        color: $default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }

    &-animate {
      position: relative;
      // height: 30px;
      height: 45px;
      line-height: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      span {
        color: rgba($default, 0.5);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      .form-control-wrapper-label-inside & {
        height: 50px;
      }

      &.form-control-valid {
        .form-control {
          & + .form-control-label {
            @include filled-input-label;
            @include pointer-without-events;
          }
        }
      }

      .form-control {
        // height: 15px;
        height: 25px;
        line-height: 1;
        border-width: 0;
        padding: 0;
        box-shadow: 0 0 0 0 $none;

        .form-control-wrapper-label-inside & {
          height: 50px;
          @extend .input__styles;
          padding: 22px 15px 0;
          width: 100%;
        }

        &:focus {
          & + .form-control-label {
            @include filled-input-label;
          }
        }
      }

      .form-control-label {
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        @include transform(translate(0, -50%));
        // font-size: $fz-14;
        @media only screen and (min-width: 1200px) {
          // font-size: $fz-13;
        }
        .ellipsis {
          .pointer-without-events {
            display: flex;
          }
        }
      }
    }
  }
}

.form-control-wrapper {
  &.search {
    position: relative;
    margin-right: 10px;

    .search-input {
      padding: 11px 30px;
      background: #f7f7f9;
      border-radius: 3px;
      position: relative;
      transition: $transition;
    }
    &:after {
      content: '';
      width: 15px;
      height: 15px;
      position: absolute;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      left: 10px;
      background-image: url('../../../assets/images/search.svg');
      top: 50%;
      transform: translateY(-45%);
    }
    @media screen and (max-width: $screen-xs-max) {
      width: auto;
      height: auto;
      .form-control-container {
        width: auto;
        height: auto;
      }
      .search-input {
        width: 40px;
        height: 40px;
        padding: 0;
        background: transparent;
        opacity: 0;
        z-index: 1;

        &:focus,
        &:active {
          right: 0;
          position: relative;
          opacity: 1;
          width: 200px;
          padding: 11px 30px;
          background-color: $main-background;
          background-image: url('../../../assets/images/search.svg');
          background-size: 15px 15px;
          background-position: 10px 50%;
          background-repeat: no-repeat;
        }
        &:parent:parent ~ *:after {
          z-index: 1;
        }
      }
      &:after {
        width: 20px;
        height: 20px;
        left: 0;
      }
    }
  }
}

input[value='']::-webkit-datetime-edit {
  color: transparent;
}

/* This to make it visible when input is clicked */
input:focus::-webkit-datetime-edit {
  color: #000;
}

.notify-checkbox {
  display: flex;
  align-items: center;
  padding: 15px 15px 0;
}

.notify-checkbox label {
  order: 2;
  padding-bottom: 0 !important;
  margin-left: 20px;
  cursor: pointer;
}

.notify-checkbox .form-control-container {
  order: 1;

  .form-control {
    width: 18px;
    height: 18px;
    cursor: pointer;

    &:after {
      top: 0;
      left: 0;
    }

    &:before {
      top: 5px;
      left: 5px;
    }
  }
}

.pointer-without-events a {
  pointer-events: auto;
}

.form-control-sufix-second {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  cursor: pointer;
}

.form-disabled {
  padding-bottom: 0px;
}

.form-control-container > input[type='checkbox'] {
  visibility: hidden;
}

.text-input-style {
  font-size: $fz-13 !important;
}

.input-symbols-counter {
  position: absolute;
  top: 15px;
  right: 0;
  border-radius: 50%;
  background-color: #191b36;
  color: #ffffff;
  padding: 3px;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
}
.input-symbols-counter2 {
  position: absolute;
  top: 15px;
  right: 0;
  border-radius: 50%;
  color: var(--main-color);
  padding: 3px;
  display: flex;
  justify-content: center;
}

input.form-control-with-counter {
  padding-right: 60px !important;
}
