.my-progress {
  width: 100%;

  .my-bar {
    width: 1%;
    height: 5px;
    background-color: grey;
    border-radius: 5px;
    margin-top: 5px;
  }
}
