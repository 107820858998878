@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.date-picker {
  &-wrapper {
    width: 100%;

    * {
      user-select: none;
    }
  }

  &-clear {
    border-width: 0;
    border-color: $none;
    background-color: $none;
  }
}

.react-datepicker {
  border-width: 0;
  border-radius: 0;
  box-shadow: 0 4px 10px 0 rgba(208, 208, 208, 0.5);

  &-wrapper {
    width: 100%;
  }

  &__input-container {
    width: 100%;
    height: 50px;

    input {
      height: 100%;
    }
  }

  &-popper {
    margin: 0 !important;
    z-index: 999999;
    height: 240px;
  }

  &__triangle {
    display: none;
  }

  &__header {
    background-color: $white;
    border-width: 0;
    padding-top: 15px;
  }

  &__navigation {
    top: 20px;
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba($default, 0.2);
    border-radius: 0;
    @include rotate(45);

    &--previous {
      left: 15px;
      border-top-width: 0;
      border-right-width: 0;
    }

    &--next {
      right: 15px;
      border-left-width: 0;
      border-bottom-width: 0;
    }
  }

  &__day {
    @include border-radius(2px);

    &:hover {
      @include border-radius(2px);
    }

    &--keyboard {
      &-selected {
        color: #fff !important;
        background-color: $success;
      }
    }

    &--today {
      color: $success;

      &.react-datepicker__day--selected {
        color: $white;
        background: $success;
      }
    }

    &--outside-month {
      color: rgba($default, 0.3);
    }

    &-names {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px 0;

      .date-picker-large & {
        padding: 15px 15px 0;
        margin: 0;
      }
    }

    &-name {
      display: flex;
      align-items: center;
      justify-content: center;

      .date-picker-large & {
        width: 44px;
        height: 44px;
        margin: 0;
      }
    }

    &--selected {
      background-color: $success;
      @include border-radius(2px);

      &:hover {
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.15),
            rgba(0, 0, 0, 0.15)
          )
          $success;
      }
    }

    .date-picker-large & {
      width: 44px;
      height: 44px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__week {
    .date-picker-large & {
      display: flex;
      justify-content: space-between;
    }
  }

  &__month {
    background: #fff;
    z-index: 5;
    margin: 0;
    border-top: 1px solid #f8f8fa;

    .date-picker-large & {
      padding: 15px;
      margin: 0;
      max-height: 400px;
      max-width: 340px;
    }

    .date-picker-large & {
      flex: 1 1 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    &-container {
      width: 100%;
      height: 50px;
      background: #fff;
      z-index: 5;

      .date-picker-large & {
        min-width: 340px;
        min-height: 400px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__current-month--hasYearDropdown,
  &__current-month--hasMonthDropdown {
    display: none;
  }

  &__header__dropdown {
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
  }

  &__navigation--years-previous {
    border-left: none;
    border-top: none;
  }

  &__navigation--years-upcoming {
    top: -15px;
    border-right: none;
    border-bottom: none;
  }
}

.react-datepicker {
  &__month-read-view,
  &__year-read-view {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;

    &--down-arrow {
      margin: 0;
      margin-left: 5px;
      border-width: 0.25rem;
      top: auto;
    }
  }
}

.datepicker {
  .form-control-valid {
    .form-control-label {
      @include filled-input-label;
    }
  }
}

.react-datepicker__year-dropdown-container {
  select.react-datepicker__year-select {
    border: 0;
  }
}

.react-datepicker__month-dropdown-container {
  select.react-datepicker__month-select {
    border: 0;
  }
}
