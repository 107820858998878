@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.info__styles {
  font-size: $fz-13;
  max-height: 50px;
  border-width: 1px;
  border-style: solid;
  @include border-radius(3px);
  width: 100%;
  height: 50px;
  padding: 15px 15px 0;
  display: flex;
  align-items: center;
}

.form-control {
  &-info {
    @extend .info__styles;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &.disabled {
      background-color: $muted;
    }

    &:hover {
      cursor: pointer;
    }

    .form-control-info-label-inside & {
      padding: 15px 30px 0 15px;
      height: 50px;
    }

    &-group {
      position: relative;
    }

    &-label {
      font-size: $fz-14;

      .form-control-info-label-outside & {
        line-height: 1;
        display: block;
        padding-bottom: 7.5px;
        color: $default;
      }

      .form-control-info-label-inside & {
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 50%;
        font-size: $fz-13;
        color: rgba($default, 0.5);
        padding-left: 15px;
        padding-right: 30px;
        @include transform(translate(0, -50%));
        transition: transform 0.2s ease, font-size 0.2s ease;
      }

      .form-control-info-label-inside.control-active & {
        font-size: $fz-10;
        @include color-opacity($default, 0.5);
        @include transform(translate(0, -100%));
      }

      &-outside {
        display: flex;
        flex-direction: column;
      }
    }

    .popup-global & {
      background-color: $soft-gray;
      border: 0;
    }
  }
}
