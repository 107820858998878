@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.main-header {
  background-color: var(--main-color);
  height: 50px;
  padding: 0 40px;
  color: white;
  .list {
    &.account {
      margin-right: -25px;

      .link-wrapper {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
    &-item-wrapper {
      .link {
        font-size: $fz-13;
        &.link-primary-secondary {
          color: $white;
        }
        &.active-white {
          color: $white;
          opacity: .7;
        }

        .message-link:hover {
          opacity: .7;
        }
      }
    }
  }
}
.mobile-menu {
  background-color: $default;
  position: fixed;
  left: 100%;
  width: 100%;
  top: 50px;
  height: calc(100vh - 50px);
  transition-property: left;
  transition-duration: 0.3s;
  padding: 20px;
  ul {
    padding: 0;
    padding-inline-start: 0;
    a {
      padding-left: 0;
    }
  }
  z-index: 9;
  &.opened {
    left: 0;
  }
}
