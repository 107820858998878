@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.breadcrumb {
  &-list {
    display: flex;
    flex-wrap: wrap;

    &-item {
      &-wrapper {
        display: flex;
        margin-top: 15px;

        &:after {
          content: '/';
          position: relative;
          top: -2px;
          font-size: $fz-18;
          color: rgba($default, 0.4);
          padding: 0 5px;
        }

        &:last-child {
          &:after {
            content: '';
          }

          .link {
            color: $default;
            pointer-events: none;
          }
        }

        .link {
          line-height: 1;
          font-size: $fz-20;
          font-weight: $bold;
          padding: 0;
        }
      }
    }
  }

  .without-tabs {
    margin: 20px 0 30px 0;
  }
}
