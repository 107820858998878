@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.la-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-svg {
    &-link {
      &-arrow {
        @include translate(3.9px, -2.5px);
      }
    }
  }

  &.wrapped {
    &-round {
      background: $soft-gray;
      border-radius: 50%;
    }
  }

  &.icon-svg {
    &-close {
      svg {
        @include rotate(45);
      }
    }
  }

  .btn-icon-single &,
  .btn-icon & {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-icon.right & {
    margin-right: 10px;
  }

  .btn-icon.left & {
    margin-left: 10px;
  }

  &:hover {
    .link-button & {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &-arrow {
    width: 8px;
    height: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(15, 34, 65, 0.2);
    border-radius: 0;
    @include rotate(45);
    display: inline-block;
    position: relative;

    &-left {
      border-left-width: 0;
      border-bottom-width: 0;
      left: -2px;
    }

    &-right {
      border-top-width: 0;
      border-right-width: 0;
      right: -2px;
    }
  }

  &-angle {
    .form-control-select-group & {
      position: absolute;
      right: 1px;
      top: 1px;
      bottom: 1px;
      margin: auto;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      pointer-events: none;
      background-color: transparent;
      @include border-radius(3px);
    }

    &:before {
      content: '';
      border-style: solid;
      overflow: hidden;
      @include border-radius(50px);
      display: inline-block;
    }
    &-up {
      &:before {
        border-width: 0px 4.5px 4.5px 4.5px;
        border-color: transparent transparent $default transparent;
      }
    }

    &-down {
      &:before {
        border-width: 4.5px 4.5px 0px 4.5px;
        border-color: $default transparent transparent transparent;
        margin-bottom: 10px;
      }
    }
  }

  .pdf-viewer & {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .with-stars & {
    cursor: pointer;
  }
}

svg {
  .icon-svg-link & {
    overflow: visible !important;
  }

  .icon-svg-cross & {
    @include rotate(-45);
  }
}
