@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.btn {
  min-width: 120px;
  height: auto;
  font-size: $fz-12;
  height: 40px;
  color: $default;
  font-weight: $medium;
  @include border-radius(3px);
  border-width: 0;
  border-style: solid;
  border-color: #eceeef;
  background-color: #f5f6f8;
  user-select: none;
  transition: color 0.1s ease, background-color 0.1s ease;

  .autocomplete-dropdown & {
    @include border-radius(0px 0px 3px 3px);
  }

  &:hover {
    background-color: rgba($default, 0.03);
  }

  &:active {
    background-color: rgba($default, 0.06);
  }

  &-content {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.clickable {
      pointer-events: all;
    }

    .btn-link & {
      display: flex;
      height: 100%;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }

  &-clear {
    padding: 0;
    min-width: 0;
    margin: 0;

    &:hover,
    &:active {
      background-color: $none;
    }
  }

  &-value {
    &-label {
      & + * {
        margin-left: 5px;
      }
    }
  }

  &-link {
    display: inline-block;
  }

  &-content {
    .btn-icon & {
      display: flex;
      align-content: center;
    }

    &.reverse {
      .btn-icon & {
        flex-direction: row-reverse;
      }

      .la-icon {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }

  &-text {
    line-height: 2;
    display: block;

    .muted {
      color: rgba($default, 0.5);
    }

    .btn-link & {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-content & {
      display: flex;
    }
  }

  &-icon {
    position: relative;

    &-single {
      min-width: 0;
      width: 40px;
      height: 40px;
      position: relative;
    }
  }

  & + .btn {
    margin-left: 20px;
  }

  &-large {
    padding: 0 15px;
    min-height: 45px;
  }

  &-wrap {
    display: flex;
  }

  &-transparent {
    background-color: transparent;
    width: auto;

    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }
  }
  @media screen and (max-width: $screen-xs-max) {
    &.cancel,
    &.plus {
      width: 40px;
      min-width: unset;
      background-color: transparent;
      color: $gray;
      background-position: center;
      background-size: 20px;
      background-repeat: no-repeat;
      font-size: 0;
      &:hover {
        background-color: transparent;
      }
    }
    &.plus {
      background-image: url('../../assets/images/plus.svg');
    }
    // &.edit {
    //   background-image: url('../../assets/images/edit.svg');
    // }
    &.cancel {
      background-image: url('../../assets/images/cross.svg');
    }
    &.update {
      position: fixed;
      bottom: 0;
      right: 0px;
      width: 100%;
      z-index: 9;

      height: 60px;
      font-size: $fz-16;
      font-weight: normal;
      border-radius: 0;
    }
  }
  &.burger {
    background-color: transparent;
    min-width: unset;
    width: 40px;
    height: 40px;
    background-image: url('../../assets/images/burger.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    transition-property: background-image;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    margin-right: -20px;
    &.opened {
      background-image: url('../../assets/images/cross.svg');
    }
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }
  }
}

.color-red {
  color: #e73056;
}
