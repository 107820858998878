@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';
.form {
  @media screen and (max-width: $screen-xs-max) {
    &.mt50 {
      margin-top: 0px;
    }
  }

  .form-control {
    background-color: $main-background;
    border-color: $main-background;
    color: $default;

    .auth-wrapper & {
      background-color: transparent;
      border-color: transparent;
    }

    .tmp-auth-wrapper & {
      background-color: transparent;
      border-color: transparent;
    }

    .xceedance-auth-wrapper & {
      background-color: transparent;
      border-color: transparent;
    }

    &.role-selector {
      background-color: #fff;
      border: 1px solid #f7f7f9;
    }
  }
  .form-group {
    position: relative;
  }
  &-group {
    &.with-popup {
      z-index: 5 !important;
    }
    &.focus-within:focus-within {
      z-index: 999999999 !important;
    }
    &:last-of-type {
      margin-bottom: 20px;
      // min-width: 400px;
    }
    .form-control {
      background-color: $main-background;
      border-color: $main-background;
      color: $default;
      .auth-wrapper & {
        background-color: transparent;
        border-color: transparent;
      }
      &.role-selector {
        background-color: #fff;
        border: 1px solid #f7f7f9;
      }
    }
    &:first-child {
      .form-control {
        .form-inline-combine & {
          border-right-width: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    & + .form-group {
      // z-index: 2 !important;
      .form-control {
        .form-inline-combine & {
          border-left-width: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    .form-inline-divide & {
      margin: 0 10px;
      max-width: calc(50% - 20px);
    }
    .form-inline & {
      width: 100%;
      position: relative;
      z-index: 80;
      // z-index: 999;
    }
  }
  &-inline {
    display: flex;
    &.splitted {
      margin-left: 0px;
      margin-right: 0px;
    }
    @media screen and (max-width: $screen-xs-max) {
      flex-direction: column;
      &.splitted {
        margin-left: -10px;
        margin-right: -10px;
      }
      .form-group {
        margin-left: 0;
        margin-right: 0;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
    &-divide {
      margin: 0 -10px;
    }
  }
  &-errors:empty {
    display: none;
  }
  .tooltip-icon {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .tooltip-wrapper-custom {
    max-width: 300px;
  }

  &-warning-message {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #000000;
  }
}
@media screen and (max-width: $screen-xs-max) {
  .-ml-10 {
    margin-left: -10px;
  }
  .-mr-10 {
    margin-right: -10px;
  }
}

.claim-details-form {
  display: flex;
  flex-direction: column;
  .form {
    @media screen and (max-width: $screen-xs-max) {
      &.mt50 {
        margin-top: 0px;
      }
    }

    @media screen and (min-width: 1200px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .form-group {
      position: relative;
      box-sizing: border-box;
      @media screen and (min-width: 1200px) {
        min-width: 45%;
        margin-right: 20px;
      }
    }
  }

  .claim-details-holder {
    @media screen and (max-width: $screen-xs-max) {
      &.mt50 {
        margin-top: 0px;
      }
    }

    @media screen and (min-width: 1200px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .form-group {
      position: relative;
      box-sizing: border-box;
      @media screen and (min-width: 1200px) {
        min-width: 45%;
        margin-right: 20px;
      }
    }
  }
}
