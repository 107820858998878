@import '../../assets/scss/variable.scss';

.trustly {
  width: 100%;
  height: 100vh;
  padding: 6em 2em 2em 2em;
  font-family: Roboto;
  text-align: center;

  &-success-image {
    max-width: 40%;

    @media screen and (min-width: $screen-xs-max) {
      max-width: 20%;
    }

    @media screen and (min-width: $screen-lg-min) {
      max-width: 15%;
    }

    @media screen and (min-width: $screen-xl-max) {
      max-width: 10%;
    }
  }

  &-header {
    margin: 1.5em 0;
  }

  &-content {
    margin: 2em 0;
  }

  &-avatar-image {
    max-width: 15%;
    position: absolute;
    bottom: 2em;
    left: 2em;

    @media screen and (min-width: $screen-xs-max) {
      max-width: 10%;
    }

    @media screen and (min-width: $screen-sm-max) {
      max-width: 6%;
    }

    @media screen and (min-width: $screen-lg-min) {
      max-width: 4%;
    }
  }
}
