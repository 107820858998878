@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.table {
  &-wrapper {
    background-color: $white;
    padding: 20px;

    &.documents-with-table {
      padding-right: 0;
      tr {
        td:last-child,
        th:last-child {
          width: 40px;
          padding-left: 10px;
          padding-right: 20px;

          @media (min-width: $screen-lg-max) {
            width: auto;
          }
        }
      }
    }

    table {
      width: 100%;

      thead {
        tr {
          height: 40px;
        }
      }

      tbody {
        tr {
          td {
            background-color: rgba($default, 0);
            @include transition(background-color, 0.2s, ease);
          }

          &:hover {
            td {
              background-color: rgba($default, 0.05);
              cursor: pointer;
            }
          }
        }
        .table-popup-handler {
          color: $success;
        }
      }

      tr {
        td,
        th {
          padding: 17px 15px 17px 15px;
        }

        th {
          font-weight: $regular;
          color: $color-secondary;
          font-size: $fz-11;
          text-transform: uppercase;
          user-select: none;
          padding-top: 0;
          font-weight: $medium;
        }

        td {
          color: $default;
          font-size: $fz-13;
          border-width: 0;
          border-top-width: 1px;
          border-style: solid;
          border-color: $border-color;

          div {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

.react {
  &-bs {
    &-table {
      .table-wrapper & {
        border-width: 0;
        @include border-radius(0);
      }
    }
  }
}

.order {
  padding: 0 10px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  & > * {
    display: flex;
  }

  &:not(.dropup) > span {
    &.caret {
      margin: 0 !important;

      &:before,
      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        overflow: hidden;
        border-radius: 50px;
        @include transition(all, 0.2s, ease);
      }

      &:before {
        top: 0;
        border-width: 0px 4.5px 4.5px 4.5px;
        border-color: transparent transparent $default transparent;
      }

      &:after {
        bottom: 0;
        border-width: 4.5px 4.5px 0px 4.5px;
        opacity: 0.3;
        border-color: $default transparent transparent transparent;
      }
    }
  }

  &.dropup > span {
    &.caret {
      margin: 0 !important;

      &:before,
      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        overflow: hidden;
        border-radius: 50px;
        @include transition(all, 0.2s, ease);
      }

      &:before {
        top: 0;
        opacity: 0.3;
        border-width: 0px 4.5px 4.5px 4.5px;
        border-color: transparent transparent $default transparent;
      }

      &:after {
        bottom: 0;
        border-width: 4.5px 4.5px 0px 4.5px;
        border-color: $default transparent transparent transparent;
      }
    }
  }

  .dropup,
  .dropdown {
    position: absolute;

    .caret {
      border-style: solid;
      overflow: hidden;
      border-radius: 50px;
      font-size: 0;
      margin: 0 !important;
    }
  }

  .dropdown {
    bottom: 0;

    .caret {
      border-width: 4.5px 4.5px 0px 4.5px;
      border-color: $default transparent transparent transparent;
    }
  }

  .dropup {
    opacity: 0.3;

    .caret {
      border-width: 0px 4.5px 4.5px 4.5px;
      border-color: transparent transparent $default transparent;
    }
  }
}

.elipsis-smart {
  div {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s linear;
    white-space: nowrap;

    &:focus,
    &:hover {
      color: transparent;
    }

    &:focus:after,
    &:hover:after {
      content: attr(data-text);
      overflow: visible;
      text-overflow: inherit;
      background: #fff;
      position: absolute;
      left: auto;
      top: auto;
      width: auto;
      max-width: 20rem;
      border: 1px solid #eaebec;
      padding: 0 0.5rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
      white-space: normal;
      word-wrap: break-word;
      display: block;
      color: black;
      margin-top: -1.25rem;
    }
  }
}
