.email-sent-header {
  padding-bottom: 52px;
  font-size: 20px;
  color: #fff;
}

.text-align-center {
  text-align: center;
}

.white-low-opacity {
  opacity: 0.3;
  color: #fff;

  &:hover {
    color: #fff;
    opacity: 0.6;
  }
}

button.btn-login {
  color: white;
  min-height: 45px;
  margin: 0px 17px;
  border-radius: 3px;
  background: #191b36;
  margin-left: 0;
  font-family: NeueHaasGrotesk;
  font-weight: 550;
  font-size: 14px;
  letter-spacing: 1px;
}
button.btn-login:hover {
  background: #191b36;
}

button.btn-login-pingone {
  color: white;
  min-height: 45px;
  margin: 30px 17px 30px 0px;
  margin-left: 0 !important;
  border-radius: 3px;
  background: #191b36;
  margin-left: 0;
  font-family: NeueHaasGrotesk;
  font-weight: 550;
  font-size: 14px;
  letter-spacing: 1px;
}
button.btn-login-pingone:hover {
  background: #191b36;
}

button.btn-login-azure {
  color: white;
  min-height: 45px;
  margin: 30px 17px 30px 0px;
  margin-left: 0 !important;
  border-radius: 3px;
  background: #191b36;
  margin-left: 0;
  font-family: NeueHaasGrotesk;
  font-weight: 550;
  font-size: 14px;
  letter-spacing: 1px;
}
button.btn-login-azure:hover {
  background: #191b36;
}
button.btn-forgot-credentials {
  color: #191b36;
  margin-top: 20px;
  letter-spacing: 1px;
  opacity: 0.7;
  font-family: PT serif;
  font-size: 14px;
  font-weight: 400;
}
button.btn-forgot-credentials:hover {
  color: #191b36;
  opacity: 1;
}
button.btn-forgot-credentials-tmp {
  color: #fff;
  margin-top: 20px;
  letter-spacing: 1px;
  opacity: 1;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}
button.btn-forgot-credentials-tmp:hover {
  color: #fff;
  opacity: 0.7;
}
button.btn-horwich-forgot-credentials {
  color: #191b36;
  letter-spacing: 1px;
  opacity: 0.7;
  font-family: PT serif;
  font-size: 14px;
  font-weight: 400;
}
button.btn-horwich-forgot-credentials:hover {
  color: #191b36;
  opacity: 1;
}
div.tmp-login-btns-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

button.btn-tmp-reset-password {
  color: white;
  min-height: 45px;
  border-radius: 3px;
  background: #191b36;
  margin-left: 0;
  font-family: NeueHaasGrotesk;
  font-weight: 550;
  font-size: 12px;
  letter-spacing: 1px;
}
button.btn-tmp-reset-password:hover {
  background: #191b36;
}

button.btn-tmp-need-help {
  color: white;
  min-height: 45px;
  margin-left: 10px;
  border-radius: 3px;
  background: #191b36;
  margin-left: 0;
  font-family: NeueHaasGrotesk;
  font-weight: 550;
  font-size: 12px;
  letter-spacing: 1px;
}
button.btn-tmp-need-help:hover {
  background: #191b36;
}

.to-login-link {
  color: #191b36;
  margin-top: 20px;
  padding: 0;
  letter-spacing: 1px;
  opacity: 0.7;
  font-family: PT serif;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 400;
  z-index: 1;
}

.to-login-link:hover {
  opacity: 1;
  z-index: 1;
}

.to-login-link-tmp {
  color: #fff;
  margin-top: 20px;
  padding: 0;
  letter-spacing: 1px;
  opacity: 1;
  font-family: Roboto;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 400;
}

.to-login-link-tmp:hover {
  color: #fff;
  opacity: 0.7;
}

.hero-img {
  flex-grow: 1;
  width: 1170px;
  height: 100%;
  background-image: url('../../assets/images/hero-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 2%;
  bottom: 0;
  @media screen and (min-width: 1440px) {
    right: 4%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    right: 0;
  }
}

.recovery-hero-img {
  flex-grow: 1;
  width: 50%;
  height: 100%;
  background-image: url('../../assets/images/g.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 0;
  bottom: 0;

  @media screen and (max-width: 1100px) {
    width: 70%;
    height: 100%;
  }
  @media screen and (max-width: 920px) {
    width: 90%;
    height: 100%;
  }
  @media screen and (max-width: 770px) {
    width: 100%;
    height: 100%;
  }
}

.login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 10;
  width: 380px;

  @media screen and (max-width: 600px) {
    position: initial;
  }

  .tmp-welcome-text {
    color: #fff;
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
  }

  .tmp-login-legal-text {
    color: #fff;
    font-family: Roboto;
    margin-top: 70px;
    text-align: center;
  }
}

.horwich-login-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10%;
  left: 20%;
  z-index: 10;
  width: 380px;

  @media screen and (max-width: 600px) {
    position: initial;
  }

  .tmp-welcome-text {
    color: #fff;
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
  }

  .tmp-login-legal-text {
    color: #fff;
    font-family: Roboto;
    margin-top: 70px;
    text-align: center;
  }
}

.xceedance-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 10;
  width: 380px;

  @media screen and (max-width: 600px) {
    position: initial;
  }
}

.login-left-tmp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  // left: 20%;
  z-index: 10;
  width: 380px;

  @media screen and (max-width: 600px) {
    position: initial;
  }

  .tmp-welcome-text {
    color: #fff;
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
  }

  .tmp-login-legal-text {
    color: #fff;
    font-family: Roboto;
    margin-top: 70px;
    text-align: center;
  }
}

.tmp-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 380px;

  .tmp-welcome-text {
    color: #fff;
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
  }

  .tmp-login-legal-text {
    color: #fff;
    font-family: Roboto;
    margin-top: 70px;
    text-align: center;
  }
}

div.form-control-wrapper {
  div.form-control-container {
    .form-control + label.login-label {
      div.ellipsis {
        span.pointer-without-events {
          font-family: PT serif;
          font-size: 16px;
        }
      }
    }
    .form-control + label.login-label.tmp-label {
      div.ellipsis {
        span.pointer-without-events {
          font-family: Roboto;
          font-size: 16px;
        }
      }
    }
  }
  div.form-control-container.form-control-label-filled {
    .form-control + label.login-label {
      div.ellipsis {
        span.pointer-without-events {
          font-family: PT serif;
          font-size: 12px;
        }
      }
    }
    .form-control + label.login-label.tmp-label {
      div.ellipsis {
        span.pointer-without-events {
          font-family: Roboto;
          font-size: 12px;
        }
      }
    }
  }
}
div.form-control-wrapper {
  div.form-control-container {
    .form-control:focus + label.login-label {
      div.ellipsis {
        span.pointer-without-events {
          font-family: PT serif;
          font-size: 12px;
        }
      }
    }

    .form-control:focus + label.login-label.tmp-label {
      div.ellipsis {
        span.pointer-without-events {
          font-family: Roboto;
          font-size: 12px;
        }
      }
    }
  }
}
.recovery-submit {
  color: white;
  min-height: 45px;
  margin-right: 17px;
  border-radius: 3px;
  background: #191b36;
  font-family: Roboto, Arial, sans-serif;
  span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.thompsons-faq {
  position: absolute;
  right: 25px;
  bottom: 25px;
}

.help {
  width: 90%;
  height: 100%;
  background-color: #ffffff;
  padding: 3rem 4rem;

  &-goback-holder {
    position: fixed;
  }

  &-goback {
    position: absolute;
    top: -1.5rem;
    left: -2.5rem;
  }

  &-title {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 3rem;
    font-weight: 500;
    font-family: sans-serif;
  }

  &-content {
    display: flex;
    justify-content: flex-end;
  }
  &-menu {
    position: fixed;
    left: 7%;
    top: 10rem;
    width: 30%;
  }
  &-main {
    width: 65%;
  }

  &-content-title {
    color: #ee3425;
    font-weight: 500;
  }

  &-content-subtitle {
    color: #ee3425;
    font-weight: 500;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mt-3 {
    margin-top: 3rem;
  }

  &-table-content {
    display: flex;
    justify-content: space-between;
    line-height: 3;
    color: #000000;
    font-weight: 500;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  &-table-subcontent {
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
    line-height: 2;
    color: #000000;
    font-weight: 500;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  &-table-subcontent-items {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    line-height: 1.5;
    color: #000000;
    font-weight: 500;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  &-page-break {
    margin: 3rem 0;
    border-bottom: 1px solid #000000;
  }

  &-list {
    margin-left: 1rem;
    li {
      list-style-type: disc;
      list-style-position: inside;
    }
  }

  &-content-text {
    &-link {
      text-decoration: underline;
    }
  }

  &-image {
    margin: 1.5rem 0;
  }

  a.active-help {
    opacity: 1;
  }
}
