@import '../../assets/scss/variable.scss';

.vehicle-inspector-container {
  padding: 5px;
  background: #f7f7f9;
  height: auto;
  border: 1px solid #acacac77;
  flex-basis: 85%;
  max-width: 1020px;
  position: relative;

  .spinner-container {
    height: 550px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    .blink {
      width: 200px;
      height: 50px;
      padding: 15px;
      text-align: center;
      line-height: 50px;
      span {
        font-size: 35px;
        font-family: 'NeueHaasGrotesk';
        color: rgb(83, 83, 83);
        animation: blink 1s linear infinite;
      }
    }
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}
.table-header,
.table-row {
  display: flex;

  &.col-1 {
    flex-basis: 10%;
  }
  &.col-2 {
    flex-basis: 20%;
    flex-grow: 1;
  }
  &.col-3 {
    flex-basis: 10%;
  }
  &.col-4 {
    flex-basis: 20%;
  }
  &.col-5 {
    flex-basis: 10%;
  }
  &.col-6 {
    flex-basis: 20%;
    flex-grow: 1;
    & .icon-container {
      justify-content: flex-end;
    }
  }
}
.table-header {
  background: #ffffff;
  color: #6e6e6e;
  padding: 6px 4px;
  font-weight: bold;
  font-size: 16px;
}
.table-row {
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  padding: 5px;
  background: #efededb3;
  border-radius: 2px;
  border-bottom: 1px solid #a7a7a7a1;
  &:nth-child(odd) {
    background: rgb(255, 255, 255);
  }
  &:hover {
    cursor: pointer;
  }
}
.table-col {
  flex: 1;
  margin-right: 5px;
  .icon-container {
    display: flex;
    & > div {
      margin: 0 6px 0 0;
    }
    span {
      text-decoration: underline;
      color: #3385de;
    }
  }
  &.col-6 {
    & .icon-container {
      justify-content: flex-end;
      & div {
        margin: 0 14px 0 0;
      }
    }
  }
}

.vehicle-inspector {
  height: 90px;
  width: 180px;
  background-image: url('../../assets/images/vehicle_inspector.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-left: 0;
  &:hover {
    cursor: pointer;
  }
}
