@import '../../../assets/scss/variable.scss';

.autocomplete {
  &-wrapper {
    &.windowed {
      width: 100%;

      input {
        background: $soft-gray;
      }
    }
    input {
      height: 50px;
    }
  }
}

.create-wrapper {
  position: absolute;
  top: 5px;
  right: -270px;

  .new-person {
    margin-right: 15px;
  }

  // .new-organization {
  // }
}

.contact-autocomplete {
  .suggestions-selected {
    padding-left: 21px;
    flex-direction: row;

    .suggestions-right {
      right: 0;
      position: absolute;
      margin: 0;

      button:first-child {
        position: absolute;
        right: -34px;
      }

      button.suggestion-link {
        position: absolute;
        right: 34px;
      }
    }

    .suggestions-left {
      .suggestion-input {
        padding: 0 15px;
      }
    }

    .full-width {
      width: 100%;
    }
  }
}
