@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.well {
  width: 100%;
  display: block;
  margin: 0 auto;
  background-color: $white;
  @include border-radius(3px);
  @include box-shadow(2px, 2px, 2px, 0, rgba(0, 0, 0, 0.2));

  &-small {
    max-width: 380px;

    @media screen and (max-width: $screen-xs-max) {
      max-width: 90%;
    }
  }
}

.login-well {
  @include border-radius(20px);
  padding: 10px 40px 40px 40px;
}

.login-horwich-well {
  @include border-radius(20px);
  padding: 10px 40px 10px 40px;
  background-color: rgba(255, 192, 203, 0.5);
  text-align: center;
}

.xceedance-login-well {
  margin-top: 90px;
}
