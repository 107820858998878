.popup {
  position: absolute;
  width: 400px;
  height: 400px;
  background: #fff;
  padding: 20px;
  z-index: 1001;
  cursor: auto;
  border: 1px solid #e8e8eb;
  -webkit-box-shadow: 0 0 15px 0 rgba(5, 14, 59, 0.1);
  -moz-box-shadow: 0 0 15px 0 rgba(5, 14, 59, 0.1);
  box-shadow: 0 0 15px 0 rgba(5, 14, 59, 0.1);
  overflow-x: auto;

  &-complete {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
}

.entity-ref-popup {
  height: 200px;
}
