@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/global.scss';
@import '../../../assets/scss/loader.scss';

.media-upload {
  width: 100%;
  min-height: 145px;
  background-color: $soft-gray;
  padding: 5px 0;

  .new-design-media-upload-label {
    border-radius: 15px 15px 0 0;
  }

  &-content {
    padding-bottom: 5px;
  }

  &-document-placeholder {
    // padding: 10px 15px;
    margin-top: 10px;
  }

  &-image {
    max-height: 30px;
    display: block;
    // margin-bottom: 25px;
  }

  &-document-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 60px;
    // height: 100px;
    // border: 1px solid rgba(gray, 0.4);
    border-radius: 5px;
    position: relative;
  }

  &-document-placeholder-v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    min-height: 120px;
    // border: 1px solid rgba(gray, 0.4);
    // border-radius: 5px;
    position: relative;

    &-image {
      background-image: url('../../../assets/images/paper-clip.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      margin-bottom: 5px;
      border: 1px solid rgba(gray, 0.4);
    }
  }

  &-file-name {
    padding-left: 20px;
  }
}
