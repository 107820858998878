@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($property, $duration, $timing) {
  -webkit-transition: $property $duration $timing;
  -moz-transition: $property $duration $timing;
  -ms-transition: $property $duration $timing;
  -o-transition: $property $duration $timing;
  transition: $property $duration $timing;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
  @include transform(scale($scale));
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin gradient($start, $end, $deg: false) {
  @if $deg {
    background: #7db9e8; /* Old browsers */
    background: -moz-linear-gradient($deg, $start 0%, $end 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      $deg,
      $start 0%,
      $end 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      $deg,
      $start 0%,
      $end 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  } @else {
    background: $end; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      $start -50%,
      $end 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      $start -50%,
      $end 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      $start -50%,
      $end 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}

@mixin box-shadow($top, $left, $blur, $width, $color, $props: false) {
  @if $props {
    -webkit-box-shadow: $top $left $blur $width $color, $props;
    -moz-box-shadow: $top $left $blur $width $color, $props;
    box-shadow: $top $left $blur $width $color, $props;
  } @else {
    -webkit-box-shadow: $top $left $blur $width $color;
    -moz-box-shadow: $top $left $blur $width $color;
    box-shadow: $top $left $blur $width $color;
  }
}

@mixin background-opacity($color, $opacity) {
  background-color: rgba($color, $opacity);
}

@mixin border-opacity($bWidth, $bType, $color, $opacity) {
  background-color: $bWidth, $bType, rgba($color, $opacity);
}

@mixin border-color-opacity($color, $opacity) {
  border-color: rgba($color, $opacity);
}

@mixin color-opacity($color, $opacity) {
  color: rgba($color, $opacity);
}

@mixin btn-box-shadow($top, $left, $blur, $width, $color, $opacity) {
  -webkit-box-shadow: $top $left $blur $width rgba($color, $opacity);
  -moz-box-shadow: $top $left $blur $width rgba($color, $opacity);
  box-shadow: $top $left $blur $width rgba($color, $opacity);
}

@mixin filled-input-label {
  font-size: $fz-10;
  width: 100%;
  @include color-opacity($default, 0.5);
  @include transform(translate(0, -100%));
}

@mixin pointer-without-events {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2rem;
  white-space: nowrap;
}

$colors: (
  'success': $success,
  'success-secondary': $success-secondary,
  'success-default': $success-default,
  'normal': $normal,
  'normal-secondary': $normal-secondary,
  'primary': $primary,
  'primary-secondary': $primary-secondary,
  'info': $info,
  'info-secondary': $info-secondary,
  'danger': $danger,
  'danger-secondary': $danger-secondary,
  'warning': $warning,
  'warning-secondary': $warning-secondary,
  'muted': $muted,
  'muted-secondary': $muted-secondary,
  'soft': $soft-gray,
  // 'error-primary': $error-primary,
);

@each $name, $code in $colors {
  .form-control {
    &-#{$name} {
      @include box-shadow(0, 0, 0, 0, #{$code}33);

      &:hover {
        border-color: $code;
      }

      &:focus {
        border-color: $code;
        @include box-shadow(0, 0, 0, 2px, #{$code}33);
      }
    }
  }

  .text {
    &-color {
      &-#{$name} {
        color: $code !important;
      }
    }
  }

  .badge {
    &-#{$name} {
      background-color: $code;
    }
  }

  .alert {
    &-#{$name} {
      background-color: $code;
      @if $name ==
        'danger' or
        $name ==
        'success' or
        $name ==
        'info' or
        $name ==
        'warning'
      {
        color: $white;
      } @else {
        color: $default;
      }
    }
  }

  .btn {
    &-#{$name} {
      color: $white;
      background-color: $code;
      border-color: $code;
      border-width: 0;
      border-color: $none;

      &:hover {
        cursor: pointer;
        color: #fff;
        border-color: $code;
        // border-color: $code + 10;
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.15),
            rgba(0, 0, 0, 0.15)
          )
          $code;
      }

      &:active {
        color: #fff;
        border-color: $code;
        // border-color: $code - 10;
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.15),
            rgba(0, 0, 0, 0.15)
          )
          $code;
      }
    }

    &-normal {
      color: #000;

      &:hover {
        background-color: $code;
        // background-color: $code - 10;
        color: #000;
      }

      &:disabled {
        color: rgba(0, 0, 0, 0.7);

        &:hover {
          background-color: $code;
        }
      }
    }

    &.color-red {
      color: $success;

      &:hover {
        background-color: $gray-light;
      }
    }
  }
}

$header: 6;
$counter: 100;

@for $i from 0 through $header {
  h#{$i},
  .h#{$i} {
    color: $headers;
  }
  .zi-#{i} {
    z-index: $i;
  }
}

@for $i from 0 through $counter {
  .row {
    &-#{$i} {
      margin-left: -$i + px;
      margin-right: -$i + px;
    }

    &--#{$i} {
      margin-top: -$i + px;
      margin-bottom: -$i + px;
    }
  }

  .padding-#{$i} {
    padding: $i + px;

    &-right {
      padding-right: $i + px;
    }

    &-top {
      padding-top: $i + px;
    }

    &-left {
      padding-left: $i + px;
    }

    &-bottom {
      padding-bottom: $i + px;
    }
  }

  .mt#{$i} {
    margin-top: $i + px;
  }

  .mb#{$i} {
    margin-bottom: $i + px;
  }

  .ml#{$i} {
    margin-left: $i + px;
  }

  .mr#{$i} {
    margin-right: $i + px;
  }

  .pb#{$i} {
    padding-bottom: $i + px;
  }

  .list-item-wrapper {
    .list-#{$i} & {
      padding-top: $i + px;
      padding-bottom: $i + px;
    }
  }

  .margin-#{$i} {
    margin: $i + px;

    &-right {
      margin-right: $i + px;
    }

    &-top {
      margin-top: $i + px;
    }

    &-left {
      margin-left: $i + px;
    }

    &-bottom {
      margin-bottom: $i + px;
    }

    &--right {
      margin-right: -$i + px;
    }

    &--top {
      margin-top: -$i + px;
    }

    &--left {
      margin-left: -$i + px;
    }

    &--bottom {
      margin-bottom: -$i + px;
    }
  }
}

$prefix: color;
$color-list: (
  'success' $prefix $success,
  'primary' $prefix $primary,
  'primary-secondary' $prefix $primary-secondary,
  'info' $prefix $info,
  'danger' $prefix $danger,
  'warning' $prefix $warning,
  'muted' $prefix $muted
);

@each $i in $color-list {
  $color: nth($i, 3);
  $name: nth($i, 1);

  .link {
    &-#{$name} {
      color: $color;
      @include transition(color, 0.1s, ease);

      &:hover {
        cursor: pointer;
        color: $color;
        // color: $color + 30;
        opacity: 0.8;
      }

      &:active {
        color: $color;
        // color: $color - 30;
      }

      &.active-white {
        color: $white;
      }
    }
  }
}
