@import '../../assets/scss/variable.scss';

.video {
  &-container {
    width: auto;
    height: auto;
    background: transparent;
    cursor: auto;

    .btn-background {
      width: auto;
      height: auto;
      position: relative;

      &.deleting {
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 1;
          background: rgba(0, 0, 0, 0.7);
        }
      }

      .background-remove {
        box-shadow: 0px 0px 5px 1px $soft-pink;
      }
    }
  }
}

video.video-content {
  width: 100px;
  height: 100px;
}

.file-empty {
  width: 100px;
  height: 100px;
  background: $main-background;
  justify-content: center;
}

.form-control-file-container {
  .new-design-video-upload-label {
    border-radius: 15px 15px 0 0;
  }
}
