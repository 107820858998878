@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.pdf-viewer {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;

  &.hide {
    display: none;
  }

  .loader.fullfilled {
    width: 60px;
    height: 60px;
  }

  .loading {
    display: flex;
    justify-content: center;
    padding: 60px 0;
  }

  .loaded {
    width: 100%;
    height: 100%;
    .loader {
      display: none;
    }
  }

  .loading {
    iframe {
      display: none;
    }
  }

  &-navigation {
    padding-top: 20px;

    button {
      background: $main-background;
      padding: 10px;
    }

    .nav-span {
      padding: 0 10px;
    }
  }

  &-document {
    width: 100%;
  }

  &-page {
    width: 100%;
    box-shadow: 0px 0px 10px 0 rgba(5, 14, 59, 0.05);

    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  .pdf-btn-container {
    display: flex;

    .delete-user-btn {
      background-color: var(--main-color);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }

    .remove_person {
      z-index: 10;
    }
  }
}

.image-preview-holder {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;

  .preview-open-image {
    position: absolute;
    top: 0;
    // top: -50px;
    right: 0;
  }
}

.external-link {
  display: flex;
  justify-content: flex-end;

  .delete-user-btn {
    background-color: var(--main-color);
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .remove_person {
    z-index: 10;
  }
}
