@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.form-control-wrapper,
.form-control-select-wrapper {
  .splitted & {
    flex: 1;
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
@media screen and (max-width: $screen-xs-max) {
  .form-control-select-wrapper {
    .splitted & {
      flex: 1;
      margin: 0 -10px;

      &:first-child {
        margin-right: 5px;
      }
    }
  }
}

.smart-input {
  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .new-design-smart-input-label {
      border-radius: 15px 15px 0 0;
    }

    .new-design-smart-input-select {
      border-radius: 0 0 0 15px;
    }

    .new-design-smart-input-right {
      border-radius: 0 0 15px 0 !important;
    }
  }

  &-list {
    display: flex;

    .new-design-smart-input-right {
      border-radius: 0 0 15px 0 !important;
    }
  }

  &-label {
    margin: 0;

    .form-control-label {
      span {
        font-size: 0.875rem;
        color: $default;
        &.form-control-label-filled {
          @include filled-input-label;
        }
      }
    }
  }
}

.smart-input-label {
  padding-left: 16px;
  background-color: #f7f7f9;
  & .form-control-label span {
    @include color-opacity($default, 0.5);
  }
}
