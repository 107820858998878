.flex {
  display: flex;

  &-example {
    background-color: rgba($info, 0.1);
  }

  &-grid {
    & > * {
      flex: 1 0 auto;
      margin: 10px;
    }
  }

  &-item {
    padding: 5px;
    min-width: 200px;
    background-color: rgba($info, 0.6);
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 10px;
  }

  &-form-wrap {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  &-container {
    display: flex;
  }

  &-full-only {
    flex: 1;
  }

  &-full {
    display: flex;
    flex: 1;
  }

  &-auto {
    flex: auto;
  }

  &-xs {
    @media (max-width: $screen-xs-max) {
      display: flex;
    }
  }

  &-xs {
    @media (max-width: $screen-xs-max) {
      display: flex;
    }
  }

  &-1 {
    flex: 1;
  }

  &-grid {
    &-50 {
      & > * {
        flex: 0 0 50%;
      }
    }

    &-2-xs {
      & > * {
        @media (max-width: $screen-xs-max) {
          flex: 0 0 50%;
        }
        @media (max-width: $screen-xs-max) {
          width: 100%;
        }
      }
    }

    &-4-sm {
      & > * {
        @media (min-width: $screen-sm-min) {
          flex: 0 0 25%;
        }
      }
    }

    &-5-lg {
      * > {
        @media (min-width: $screen-lg-min) {
          flex: 0 0 20%;
        }
      }
    }
  }
}

.flex-wrap-xs {
  @media (max-width: $screen-xs-max) {
    display: flex;
    flex-wrap: wrap;
  }
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.justify {
  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }

  &-evenly {
    justify-content: space-evenly;
  }

  &-end {
    justify-content: flex-end;
  }

  &-center {
    justify-content: center;
  }
}

.items {
  &-center {
    align-items: center !important;
  }

  &-start {
    align-items: flex-start;
  }

  &-end {
    align-items: flex-end;
  }

  &-baseline {
    align-items: baseline;
  }
  &-stretch {
    align-items: stretch;
  }
}

.self-center {
  margin: auto;
}

.direction {
  &-column {
    flex-direction: column;

    &-rev {
      flex-direction: column-reverse;
    }

    &-xs {
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }

  &-row {
    flex-direction: row;

    &-rev {
      flex-direction: row-reverse;

      &-xs {
        @media (max-width: 767px) {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

.margin-10-right {
  .direction-row-rev-xs & {
    @media (max-width: 767px) {
      margin-right: 0;
    }
  }
}

.self {
  &-start {
    align-self: flex-start;
  }

  &-end {
    align-self: flex-end;
  }
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.order {
  &-primary {
    order: 1;
  }

  &-secondary {
    order: 2;
  }
}

.main-flex-list-wrapper {
  min-height: 50px;
}

.order {
  &-list {
    display: flex;
    flex-direction: column;

    & > * {
      order: 0;
    }

    & > .order-last {
      order: 1;
      align-self: center;
    }
  }
}
