@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.radio {
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &-disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  &-group {
    display: flex;
    margin: -12px;

    &-item {
      &-wrapper {
        padding: 12px;
      }
    }
  }

  &-label {
    font-size: $fz-13;
    margin-left: 12px;
    user-select: none;

    .radio-disabled & {
      color: $muted;
      cursor: not-allowed;
    }
  }
}

input {
  &[type='checkbox'],
  &[type='radio'] {
    height: 10px;
    width: 10px;
    position: relative;

    &:checked {
      &:before {
      }

      &:after {
        background-color: $primary;
      }
    }

    &:indeterminate {
      &:before {
        .table & {
          box-shadow: inset 0px -2px 0px 0px #fff;
          @include rotate(0);
        }
      }

      &:after {
        .table & {
          background-color: $primary;
        }
      }
    }

    &[disabled] {
      pointer-events: none;
    }

    &:before {
      width: 8px;
      height: 5px;
      box-shadow: inset 2px -2px 0px 0px $white;
      top: 1px;
      left: 1px;
      @include rotate(-45);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      transition: all 0.2s ease;
    }

    &:after {
      top: -4px;
      left: -4px;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border-width: 1px;
      border-style: solid;
      border-color: rgba($default, 0.2);
      background-color: $white;
      z-index: 1;
    }
  }
}
