@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.badge {
  width: 5px;
  height: 5px;
  @include border-radius(100%);

  &-text {
    font-size: $fz-13;
  }

  & + .badge-text {
    margin-left: 15px;
  }

  &-wrapper {
    display: flex;
    align-items: center;
  }
}
