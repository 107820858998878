@import '../../../assets/scss/variable.scss';

.repair-status-login-holder {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-image: url('../../../assets/images/car-service-sg.jpg');
  background-position: center;
  background-size: cover;

  .repair-status-login {
    margin-top: 100px;
    border-radius: 5px;
    background-color: white;
    height: 500px;
    width: 500px;
    z-index: 50;

    @media screen and (max-width: $screen-sm-max) {
      width: 380px;
    }

    @media screen and (max-width: 380px) {
      width: 360px;
    }

    @media screen and (orientation: landscape) and (max-device-height: 480px) {
      margin-top: 20px;
      height: 360px;
    }

    @media screen and (orientation: landscape) and (max-device-height: 380px) and (max-device-width: 680px) {
      margin-top: 8px;
      height: 360px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-brand {
      height: 67px;
      margin-top: 40px;

      @media screen and (orientation: landscape) and (max-device-height: 480px) {
        margin-top: 20px;
      }
    }

    &-content {
      height: 200px;
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (orientation: landscape) and (max-device-height: 480px) {
        padding-top: 20px;
      }
    }

    &-continue {
      height: 40px;
      width: 150px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #cfab2b;
      // background-color: #efc534;
      border-radius: 2px;
      padding: 11px 15px;
      color: white;
      margin-bottom: 40px;

      @media screen and (orientation: landscape) and (max-device-height: 480px) {
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }

    &-license {
      height: 100px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-plate {
        margin-top: 20px;
        height: 50px;
        position: relative;

        &-country {
          height: 50px;
          width: 40px;
          background-color: #0c0c99;
          color: white;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 5px 0 0 5px;
          position: absolute;

          &-code {
            font-size: 0.75rem;
            font-weight: 600;
          }
        }

        &-input {
          height: 50px;
          width: 200px;
          border-radius: 5px;
          padding: 10px 10px 10px 70px;
          border-top: 1px solid #cfab2b;
          border-bottom: 1px solid #cfab2b;
          border-right: 1px solid #cfab2b;
          background-color: #e8c205;
          font-size: 1.2rem;
          font-weight: 600;
        }

        &-input::placeholder {
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
    }

    &-date {
      height: 100px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-input {
        margin-top: 20px;
        height: 50px;
        border: 1px solid lightgrey;
        width: 200px;
        border-radius: 5px;
        // color: lightgrey;
        padding: 5px 10px;
      }

      input[type='date']::-webkit-calendar-picker-indicator {
        filter: invert(50%) saturate(500%) hue-rotate(180deg); /* Example filter to change color */
        cursor: pointer;
        transform: scale(1.4);
      }
    }

    &-error {
      color: red;
      height: 30px;
      // visibility: hidden;
      padding: 5px 20px;
      text-align: center;
    }

    &-show-error {
      visibility: initial;
    }
  }
}

.repair-status-holder {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-image: url('../../../assets/images/car-service-sg.jpg');
  background-position: center;
  background-size: cover;
}
.repair-status {
  margin: 100px 0;
  border-radius: 5px;
  background-color: white;
  width: 800px;
  z-index: 50;
  height: auto;
  padding: 40px;

  @media screen and (max-width: $screen-sm-max) {
    width: 380px;
  }

  @media screen and (orientation: landscape) and (max-device-height: 480px) {
    margin: 20px 0;
  }

  @media screen and (orientation: landscape) and (max-device-height: 380px) and (max-device-width: 680px) {
    margin: 20px 0;
    width: 650px;
  }

  &-brand {
    padding: 0;
    display: flex;
    justify-content: center;
    height: 79px;
  }

  &-statuses {
    margin: 50px 0;

    @media screen and (orientation: landscape) {
      margin: 20px 0;
    }
  }

  &-date {
    display: flex;
    align-items: flex-start;

    &-content {
      margin-left: 15px;
      font-size: 1.1rem;
      font-weight: 600;

      &-title {
        margin-bottom: 5px;
      }

      &-value {
        margin-bottom: 10px;
      }
    }
  }

  &-section {
    &-title {
      font-weight: 600;
      margin: 50px 0 20px 0;
    }

    &-divider {
      border-bottom: 1px solid lightgray;
      margin-bottom: 20px;
    }

    &-content {
      display: flex;

      &-key {
        width: 50%;
        padding: 10px 0;

        @media screen and (max-width: $screen-sm-max) {
          font-size: 0.9rem;
          width: 45%;
          margin-right: 5px;
        }
      }

      &-value {
        width: 50%;
        padding: 10px 0;
        overflow-wrap: break-word;

        @media screen and (max-width: $screen-sm-max) {
          font-size: 0.9rem;
        }

        &-outlined {
          color: #cfab2b;
          // color: #e8c205;
          text-decoration: underline;

          @media screen and (max-width: $screen-sm-max) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

.repair-status-progress {
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }

  &-step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // position: relative;
    // top: 19px;

    @media screen and (max-width: $screen-sm-max) {
      flex-direction: row;
    }

    &-text {
      width: 110px;
      height: 80px;
      padding: 10px;
      text-align: center;

      @media screen and (max-width: $screen-sm-max) {
        width: auto;
        height: auto;
        position: relative;
        bottom: 10px;
        text-align: start;
      }
    }

    &-progression {
      display: flex;
      align-items: center;
      position: relative;
      left: 40px;

      @media screen and (max-width: $screen-sm-max) {
        flex-direction: column;
        left: 0;
      }
    }
  }

  &-point {
    // position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: lightgrey;

    &-active {
      width: 25px;
      height: 25px;
      background-color: #cfab2b;

      @media screen and (max-width: $screen-sm-max) {
        position: relative;
        left: -2px;
      }
    }

    &-achieve {
      background-color: white;
      border: 2px solid lightgray;
    }
  }

  &-connector {
    width: 90px;
    border-bottom: 3px solid lightgray;

    @media screen and (max-width: $screen-sm-max) {
      width: 0;
      height: 40px;
      border-right: 3px solid lightgray;
      border-bottom: 0;
    }

    &-active {
      border-bottom: 3px solid #cfab2b;

      @media screen and (max-width: $screen-sm-max) {
        width: 0;
        height: 40px;
        border-right: 3px solid #cfab2b;
        left: -2px;
        position: relative;
      }
    }
  }
}
