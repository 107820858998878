@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';

.brand {
  padding: 10px 15px;
  margin-left: -25px;
  height: 100%;
  z-index: 100;
  // z-index: 999;

  img {
    max-height: 100%;
  }

  &.max-width-80 {
    img {
      max-width: 80px;
    }
  }
  &.max-width-60 {
    img {
      max-width: 60px;
    }
  }
}

.xceedance-logo {
  position: absolute;
  top: 30px;
  left: 30px;
}
