@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.autocomplete {
  &-dropdown {
    &-container {
      position: absolute;
      top: 100%;
      z-index: 2;
      background: $white;
      width: 100%;
      padding: 0 15px;

      .suggestion {
        &-item {
          padding: 5px 0;
          cursor: pointer;

          &:hover {
            background: $soft-gray;
          }

          span {
            color: rgba(5, 14, 59, 0.8);
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}

.map-wrap {
  width: 100%;
  height: 250px;
  background: yellowgreen;
}

.map-component {
  border-radius: 10px;
  padding: 20px;
  color: red;
  background-image: url(../../assets/images/pin-in-the-map.svg);
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);

  .point-text {
    display: inline-block;
    width: 100px;
    margin-left: 20px;
  }
}

.form-control-wrapper {
  .new-design-google-autocomplete-label {
    border-radius: 15px 15px 0 0 !important;
  }
}
