@import '../../assets/scss/variable.scss';

.basic-single {
  min-height: 50px;
  // height: 50px;
  margin-top: 0 !important;

  .select__control {
    min-height: 50px;
    // height: 50px;
    background-color: #f7f7f9;
    border-color: #f7f7f9;
    color: #050e3b;

    .css-1hwfws3 {
      font-size: 0.8125rem;
    }
  }
}

.new-design-multi-select {
  min-height: 50px;
  // height: 50px;
  margin-top: 0 !important;

  .select__control {
    min-height: 50px;
    // height: 50px;
    background-color: #f7f7f9;
    // border-color: #f7f7f9;
    color: #050e3b;
    border-radius: 15px;

    .css-1hwfws3 {
      font-size: 0.8125rem;
      border-radius: 15px;
    }
  }

  .select__placeholder {
    color: rgba($primary, 0.7);
  }
}
