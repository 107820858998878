.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);

  .popup-global {
    z-index: 11;
    width: 600px;
    height: 400px;
    background: #fff;

    .popup-inner {
      height: 100%;
      padding: 35px 25px;
    }
  }

  .white-bordered-popup {
    z-index: 9999999999;
    background: #fff;
    border-radius: 25px;
    padding: 30px 20px 20px;
    position: relative;
    max-height: 95%;
    overflow: auto;

    .cross-wrapper {
      border-radius: 50%;
      background-color: #ececec;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;

      .cross {
        position: relative;

        &:before {
          display: block;
          content: '';
          width: 2px;
          height: 10px;
          background-color: #000;
          position: absolute;
          left: 9px;
          top: 5px;
          transform: rotate(45deg);
        }

        &:after {
          display: block;
          content: '';
          width: 2px;
          height: 10px;
          background-color: #000;
          position: absolute;
          left: 9px;
          top: 5px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
