@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.built-in-header ~ .main-wrapper,
.main-controls ~ .main-wrapper {
  min-height: calc(100vh - 140px);

  @media screen and (max-width: $screen-xs-max) {
    .main-wrapper {
      padding-bottom: 80px;
    }
  }
}

.main {
  &-wrapper {
    align-items: stretch;
    background-color: $main-background;
    min-height: calc(100vh - 50px);
    padding: 20px 30px;

    &.height-auto {
      .main-content {
        height: 100%;
        min-height: unset;
      }
    }

    &-with-aside {
      justify-content: space-between;

      .main-content {
      }

      .main-aside {
        flex-basis: calc(30% - 20px);
        margin-left: 20px;
        max-width: 300px;
      }

      @media screen and (max-width: $screen-lg-min) {
        flex-direction: column;
        justify-content: flex-start;

        .main-aside {
          margin-left: 0;
          margin-top: 20px;
          flex-basis: 100%;
          max-width: unset;
          min-height: 200px;
        }
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      padding: 0;
    }
  }

  &-controls {
    align-items: center;
    height: 90px;
    width: 100%;
    padding: 20px 30px;
    position: relative;
    @include box-shadow(0, 2px, 10px, 1px, $box-shadow-color);
    border-bottom: 1px solid $border-color;

    &-content {
      width: 100%;

      .task-name-holder {
        @media screen and (min-width: 800px) and (max-width: 850px) {
          width: 50%;
        }

        @media screen and (min-width: $screen-sm-min) and (max-width: 799px) {
          width: 30%;
        }
      }
    }

    .h2 {
      margin-bottom: 0;
      line-height: 40px;
      font-size: $fz-20;
    }

    .container-fluid {
      padding: 0;
    }

    @media screen and (max-width: $screen-xs-max) {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
      padding: 20px 20px 0 !important;
    }
  }

  &-aside {
    flex-shrink: 0;
    overflow: auto;

    background-color: $white;
    border: 1px solid $border-color;
    @include box-shadow(0, 0, 15px, 0, $box-shadow-color);
    @include border-radius($border-radius);
  }

  &-content {
    width: 100%;
    padding: 0;
    background-color: $white;
    border: 1px solid $border-color;
    @include box-shadow(0, 0, 15px, 0, $box-shadow-color);
    @include border-radius($border-radius);

    .main-controls {
      box-shadow: none;
      padding: 20px;
    }

    .details {
      &-with-aside {
        width: 100%;

        @media screen and (max-width: $screen-xs-max) {
          .details-aside {
            display: none;
          }
          .details-content {
            padding-left: 20px;
          }
        }
      }

      &-aside {
        flex-basis: 210px;
        padding: 20px 90px 20px 20px;

        .list {
          &-wrapper {
            margin-top: -14px;
            position: sticky;
            top: 0px;
          }

          &-item-wrapper {
            .link {
              &-wrapper {
              }

              &-clickable {
                display: flex;
                margin: 0;
                font-size: $fz-14;
                font-weight: 500;
              }
            }
          }
        }
      }

      &-content {
        width: 100%;
        flex: 1;
        padding: 20px 20px 0;
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      @include border-radius(0);
      border: none;
    }
  }
}

.content-title {
  padding: 11px 0;
  margin-bottom: 30px;
  font-size: $fz-18;
  line-height: $fz-18;
  font-weight: normal;
}

.auth-wrapper {
  min-height: 100vh;
  background: $primary-redesign;

  .brand {
    margin-left: 0;
  }
}
.tmp-auth-wrapper {
  min-height: 100vh;
  background: #ee3425;

  .brand {
    margin-left: 0;
  }
}

.xceedance-auth-wrapper {
  min-height: 100vh;
  // background: #EE3425;

  .brand {
    margin-left: 0;
  }
}
