div.web[data-puzzel-chat][data-view='bubble'] {
  bottom: 0 !important;
  top: 95% !important;
}

div.web[data-puzzel-chat].web:not([data-view='bubble']) {
  top: calc(97% - 500px);
  left: calc(99% - 300px);
}

div.web[data-puzzel-chat].web:not([data-view='bubble']):not(.is-dragged):not(
    .is-resized
  ) {
  transform: translate(calc(0% + 0px), calc(0% - 0px));
}
