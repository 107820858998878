@import '../../../assets/scss/variable.scss';
@import '../../../assets/scss/mixins.scss';

.resend-password-button {
  min-width: 120px;
  max-width: 170px;
  height: auto;
  font-size: $fz-12;
  height: 40px;
  font-weight: $medium;
  border-radius: 3px;
  color: $default;
  font-weight: $medium;
  @include border-radius(3px);
  border-width: 0;
  border-style: solid;
  border-color: #eceeef;
  background-color: #f5f6f8;
  user-select: none;
  transition: color 0.1s ease, background-color 0.1s ease;
  margin: 20px 0;

  &:hover {
    background-color: rgba($default, 0.03);
  }
  &:active {
    background-color: rgba($default, 0.06);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.re-sent {
    background: #b4b7c3;
    color: #fff;

    span {
      margin-right: 20px;
    }
  }
}
