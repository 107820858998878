@import '../../assets/scss/variable.scss';
@import '../../assets/scss/mixins.scss';

.international-phone {
  .react-phone-number-input {
    &__row {
      display: flex;
      height: 100%;
    }

    &__country {
      width: 40px;
      display: flex;
      position: relative;
      align-items: center;

      &-select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;

        &-arrow {
          display: block;
          content: '';
          width: 0;
          height: 0;
          margin-bottom: 0.1em;
          margin-top: 0.3em;
          margin-left: 0.3em;
          border-width: 0.35em 0.2em 0 0.2em;
          border-style: solid;
          border-left-color: transparent;
          border-right-color: transparent;
          color: #b8bdc4;
          opacity: 0.7;
          -webkit-transition: color 0.1s;
          -o-transition: color 0.1s;
          transition: color 0.1s;
        }
      }
    }

    &__icon {
      width: 22px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
      }
    }

    &__input {
      width: calc(100% - 45px);
      border: 0;
      background: transparent;

      &:focus {
        & + .form-control-label {
          @include filled-input-label;
        }
      }
    }
  }

  .PhoneInput {
    width: 40px;
    display: flex;
    position: relative;
    align-items: center;

    .PhoneInputCountry {
      width: 40px;
      display: flex;
      position: relative;
      align-items: center;
      height: 100%;

      .PhoneInputCountrySelect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;

        .PhoneInputCountrySelectArrow {
          display: block;
          content: '';
          width: 0;
          height: 0;
          margin-bottom: 0.1em;
          margin-top: 0.3em;
          margin-left: 0.3em;
          border-width: 0.35em 0.2em 0 0.2em;
          border-style: solid;
          border-left-color: transparent;
          border-right-color: transparent;
          color: #b8bdc4;
          opacity: 0.7;
          -webkit-transition: color 0.1s;
          -o-transition: color 0.1s;
          transition: color 0.1s;
        }
      }

      .PhoneInputCountryIcon {
        width: 22px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 20px;
        }
      }

      .PhoneInputCountrySelectArrow {
        display: block;
          content: '';
          width: 0;
          height: 0;
          margin-bottom: 0.1em;
          margin-top: 0.3em;
          margin-left: 0.3em;
          border-width: 0.35em 0.2em 0 0.2em;
          border-style: solid;
          border-left-color: transparent;
          border-right-color: transparent;
          color: #b8bdc4;
          opacity: 0.7;
          -webkit-transition: color 0.1s;
          -o-transition: color 0.1s;
          transition: color 0.1s;
      }
    }

    .PhoneInputInput {
      width: calc(100% - 45px);
      border: 0;
      background: transparent;

      &-focus {
        & + .form-control-label {
          @include filled-input-label;
        }
      }
    }
  }

  .form-control-label {
    transform: translate(55%, -50%);
    -moz-transform: translate(55%, -50%);
    -o-transform: translate(55%, -50%);
    -ms-transform: translate(55%, -50%);
    -webkit-transform: translate(55%, -50%);
  }

  .form-control-label-animate.form-control-valid .form-control + .form-control-label.disabled-label{
    transform: translate(0, -50%);
    font-size: $fz-14;
  }

  .form-control-empty {
    .form-control {
      padding: 0 15px;
    }
  }
}
